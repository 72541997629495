export const API = "https://app.universitysolutions.in";

export const ReportAPI =
  window.location.protocol + "//universitysolutions.in/univadmin/app.php?a=";

// export const API = "http://localhost:8080";

export const univadmin = "https://universitysolutions.in/univadmin/";

export const NewApi = "https://universitysolutions.in/univadmin/app.php?";

export const digiApi = "https://tdvsphp.universitysolutions.in/app.php?&a=";

export const tdvsphp = "https://universitysolutions.in/tdvs-php/app.php?a=";

import React, { useContext, useEffect, useState, useRef } from "react";
import digiNodeApi from "../../../api/digiNodeApiSecure";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../context/appContext";
import { tdvsphp, API, NewApi } from "../../../api/consts";
import axios from "axios";
import {
  Card,
  Modal,
  Icon,
  Container,
  IconGroup,
  Button,
  Divider,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const NaMismatchValuation = () => {
  const params = useParams();
  const { teachcode, err_msg, load } = useContext(AppContext);
  const [loader, setLoader] = load;
  const [error, dispatch_err] = err_msg;
  const fteachcode = sessionStorage.getItem("fteachcode");
  const history = useHistory();

  const ref = useRef([]);

  const [dummyno, setDummyno] = useState("");
  const [filename, setFileNames] = useState([]);
  const [filepath, setFilepath] = useState("");
  const [qpSchemepath, setQpSchemePath] = useState("");
  const [parts, setParts] = useState([]);
  const [path, setPath] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [novalData, setNoValData] = useState([]);
  const [marks, setMarks] = useState(0);
  const [exitOpen, setExitOpen] = useState(false);
  const [arReason, setArReason] = useState("Script Re-scan request");
  const [arComment, setArComment] = useState("");
  const [remarks, setRemarks] = useState("");
  const [evalData, setevalData] = useState([]);
  const [sumData, setsumData] = useState([]);
  const [finalOpen, setFinalOpen] = useState(false);
  const [omrmarks, setOmrMarks] = useState("");
  const [qndata, setQnData] = useState({});
  const [qnModalOpen, setQnModalOpen] = useState(false);
  const [isViewQn, setIsViewQn] = useState(false);
  const [rotation, setRotation] = useState({});
  const [zoomLevel, setZoomLevel] = useState({});
  const [totmarks, setTotMarks] = useState({
    fmarks11: 0,
    fmarks21: 0,
    fmarks31: 0,
    fmarks41: 0,
    fmarks51: 0,
    fmarksr1: 0,
    fmarksd1: 0,
    fmarksv1: 0,
  });
  const [data, setData] = useState([]);
  const [finalTotal, setFinalTotal] = useState([]);

  let { qpcode, regcode, valno } = params;

  useEffect(() => {
    console.log(sessionStorage.getItem("isViewQn"));
    if (sessionStorage.getItem("isViewQn") == null) {
      setIsViewQn(true);
    } else if (sessionStorage.getItem("isViewQn") == "true") {
      setIsViewQn(true);
    } else if (sessionStorage.getItem("isViewQn") == "false") {
      setIsViewQn(false);
    }
    getDummyNumber();
  }, []);

  const getDummyNumber = async () => {
    try {
      setLoader(true);
      const res = await axios.get(
        tdvsphp +
          "getNaDummyNo&univcode=" +
          localStorage.getItem("univcode") +
          `&qpcode=${qpcode}&regcode=${regcode}&valno=${valno}`
      );
      setLoader(false);
      if (res.data.error_code === 0) {
        setDummyno(res.data.data.fdummyno);
        getEvaluationImages(res.data.data.fdummyno);
        getEvalPartsState(res.data.data.fdummyno);
      } else {
        dispatch_err({
          type: "OPEN_MSG_BOX",
          payload: {
            header: "Message",
            message: res.data.data.msg,
          },
        });
      }
    } catch (error) {
      dispatch_err({
        type: "OPEN_MSG_BOX",
        payload: {
          header: "Error",
          message: "Error=>" + error.message,
        },
      });
      setLoader(false);
      return;
    }
  };

  const getEvaluationImages = async (regcode) => {
    try {
      setLoader(true);
      const res = await digiNodeApi.get(
        `/eval/get-eval-image?qpcode=${qpcode}&regcode=${regcode}&valno=${valno}&fteachcode=${localStorage.getItem(
          "fteachcode"
        )}`
      );
      setLoader(false);
      console.log(res);

      if (res.data.status === 0) {
        setFileNames(res.data.result.filenames);
        setFilepath(res.data.result.ffilepath);
        setQpSchemePath(res.data.result.qpschmepath);
      } else {
        dispatch_err({
          type: "OPEN_MSG_BOX",
          payload: {
            header: "Message",
            message: "Error while reading answer booklet",
          },
        });
        setLoader(false);
        return;
      }
    } catch (error) {
      dispatch_err({
        type: "OPEN_MSG_BOX",
        payload: {
          header: "Error",
          message: "Error => " + error.message,
        },
      });
      setLoader(false);
      return;
    }
  };

  const getEvalPartsState = async (dummyno) => {
    try {
      setLoader(true);
      const res = await axios.get(
        tdvsphp +
          `getEvalNaPartsState&univcode=${localStorage.getItem(
            "univcode"
          )}&fteachcode=${fteachcode}&qpcode=${qpcode}&dummyno=${dummyno}&valno=${valno}&regcode=${regcode}`
      );
      setLoader(false);
      if (res.data.error_code === 0) {
        setParts(res.data.data.parts);
        setPath(res.data.data.path);
        // setQuestions(res.data.data.question);
        setNoValData(res.data.data.valno);
        if (res.data.data.path.length > 0) {
          setOmrMarks(res.data.data.path.fomrmarks);
        }
        let obj = {
          fmarks11: 0,
          fmarks21: 0,
          fmarks31: 0,
          fmarks41: 0,
          fmarks51: 0,
          fmarksr1: 0,
          fmarksd1: 0,
          fmarksv1: 0,
        };

        let data = res.data.data.question.map((el, i) => {
          let mark1 =
            parseFloat(el.fmarks11) <= 0 ||
            el.fmarks11 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarks11;
          obj.fmarks11 += parseFloat(mark1);
          let mark2 =
            parseFloat(el.fmarks21) <= 0 ||
            el.fmarks21 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarks21;
          obj.fmarks21 += parseFloat(mark2);
          let mark3 =
            parseFloat(el.fmarks31) <= 0 ||
            el.fmarks31 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarks31;
          obj.fmarks31 += parseFloat(mark3);
          let mark4 =
            parseFloat(el.fmarks41) <= 0 ||
            el.fmarks41 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarks41;
          obj.fmarks41 += parseFloat(mark4);
          let mark5 =
            parseFloat(el.fmarks51) <= 0 ||
            el.fmarks51 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarks51;
          obj.fmarks51 += parseFloat(mark5);
          let markr =
            parseFloat(el.fmarksr1) <= 0 ||
            el.fmarksr1 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarksr1;
          obj.fmarksr1 += parseFloat(markr);
          let markd =
            parseFloat(el.fmarksd1) <= 0 ||
            el.fmarksd1 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarksd1;
          obj.fmarksd1 += parseFloat(markd);
          let markv =
            parseFloat(el.fmarksv1) <= 0 ||
            el.fmarksv1 == "" ||
            el.fsubqn == "T"
              ? 0
              : el.fmarksv1;
          obj.fmarksv1 += parseFloat(markv);
          // console.log(el, "el");

          el.disabledstatus =
            el.fmarks11 == "-2.0" ||
            el.fmarks21 == "-2.0" ||
            el.fmarks31 == "-2.0" ||
            el.fmarks41 == "-2.0" ||
            el.fmarks51 == "-2.0" ||
            el.fmarksr1 == "-2.0" ||
            el.fmarksd1 == "-2.0"
              ? false
              : true;
          // if (res.data.data.valno.length == 3) {
          //   el.disabled =
          //     (el.fmarks11 == "-2.0" &&
          //       el.fmarks21 != "-2.0" &&
          //       el.fmarksd1 != "-2.0") ||
          //     (el.fmarks11 == "-2.0" &&
          //       el.fmarks21 == "-2.0" &&
          //       el.fmarksd1 != "-2.0") ||
          //     (el.fmarks11 != "-2.0" &&
          //       el.fmarks21 == "-2.0" &&
          //       el.fmarksd1 == "-2.0") ||
          //     (el.fmarks11 !== "-2.0" &&
          //       el.fmarks21 !== "-2.0" &&
          //       el.fmarksd1 == "-2.0")
          //       ? false
          //       : true;
          // } else if (res.data.data.valno.length == 2) {
          //   el.disabled =
          //     (el.fmarks11 == "-2.0" && el.fmarks21 != "-2.0") ||
          //     (el.fmarks11 != "-2.0" && el.fmarks21 == "-2.0")
          //       ? false
          //       : true;
          // }
          return el;
        });

        // console.log(res.data.data.question, "Ssdksjd");
        setQuestions(data);
        setTotMarks({ ...totmarks, ...obj });
      } else {
        dispatch_err({
          type: "OPEN_MSG_BOX",
          payload: {
            header: "Error",
            message: res.data.data.msg,
          },
        });
        setLoader(false);
        return;
      }
    } catch (error) {
      dispatch_err({
        type: "OPEN_MSG_BOX",
        payload: {
          header: "Error",
          message: error.message,
        },
      });
      setLoader(false);
      return;
    }
  };

  const changeMarksHandler = async (e, index, column) => {
    const regex = /^[0-9Nn.]*$/;
    regex.test(e.target.value);
    if (regex.test(e.target.value)) {
      let arr = [...questions];
      if (e.target.value == "") {
        arr[index][column] = -1;
      } else {
        arr[index][column] =
          e.target.value.toUpperCase() === "N"
            ? -2
            : e.target.value.toUpperCase();
      }
      arr[index]["onchnage"] = "T";
      setQuestions([...arr]);
    }
    console.log(questions);

    let tot = 0;

    questions.map((el, i) => {
      let mark =
        parseFloat(el[column]) <= 0 ||
        el[column] == "" ||
        el[column] == "T" ||
        el[column] == "N" ||
        el[column] == "A" ||
        el[column] == "NA" ||
        el[column] == "." ||
        el.fsubqn == "T"
          ? 0
          : el[column];
      tot += parseFloat(mark);
    });

    setTotMarks({ ...totmarks, [column]: tot });
  };

  const changePageNoHandler = (e, index, column) => {
    let arr = [...questions];
    arr[index][column] = e.target.value;
    setQuestions([...arr]);
  };

  const blurHandler = async (que, index, column) => {
    console.log(que, index, column);
    let arr = [...questions];

    if (parseFloat(que[column]) > parseFloat(que.fmaxmarks)) {
      dispatch_err({
        type: "OPEN_MSG_BOX",
        payload: {
          header: "Message",
          message: "Entered marks is greater than max marks",
        },
      });
      arr[index][column] = -1;
      setQuestions([...arr]);
    }

    let tot = 0;

    questions.map((el, i) => {
      let mark =
        parseFloat(el[column]) <= 0 ||
        el[column] == "" ||
        el[column] == "T" ||
        el[column] == "N" ||
        el[column] == "A" ||
        el[column] == "NA" ||
        el[column] == "." ||
        el.fsubqn == "T"
          ? 0
          : el[column];
      tot += parseFloat(mark);
    });

    setTotMarks({ ...totmarks, [column]: tot });
  };

  const saveHandler = async (param) => {
    try {
      if (param == "save") {
        let map = [];
        for (let i = 0; i < novalData.length; i++) {
          console.log(novalData[i], "i");

          if (novalData[i].fvalno == 1) {
            let filetered = questions.filter((item) => item.fmarks11 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
          if (novalData[i].fvalno == 2) {
            let filetered = questions.filter((item) => item.fmarks21 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
          if (novalData[i].fvalno == 3) {
            let filetered = questions.filter((item) => item.fmarks31 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
          if (novalData[i].fvalno == 4) {
            let filetered = questions.filter((item) => item.fmarks41 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
          if (novalData[i].fvalno == 5) {
            let filetered = questions.filter((item) => item.fmarks51 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
          if (novalData[i].fvalno == "R") {
            let filetered = questions.filter((item) => item.fmarksr1 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
          if (novalData[i].fvalno == "D") {
            let filetered = questions.filter((item) => item.fmarksd1 == "-1");
            if (filetered.length > 0) {
              map.push(filetered);
            }
          }
        }

        if (map.length > 0) {
          dispatch_err({
            type: "OPEN_MSG_BOX",
            payload: {
              header: "Message",
              message: "Marks field should not be empty",
            },
          });
          return;
        }

        let pagenoArr = [];
        let filetered = questions.filter(
          (item) =>
            item.onchnage == "T" &&
            (item.fpageno == undefined || item.fpageno == "")
        );
        if (filetered.length > 0) {
          pagenoArr.push(filetered);
        }

        if (pagenoArr.length > 0) {
          dispatch_err({
            type: "OPEN_MSG_BOX",
            payload: {
              header: "Message",
              message: "Please enter page number for valued questions",
            },
          });
          return;
        }

        setLoader(true);

        const res = await axios // PHP
          .post(
            tdvsphp +
              `saveEvaluationNaMarks&univcode=${localStorage.getItem(
                "univcode"
              )}&fteachcode=${fteachcode}&qpcode=${qpcode}&dummyno=${dummyno}&valno=${valno}&regcode=${regcode}`,
            questions
          );
        setLoader(false);
        console.log(res);
        if (res.data.error_code === 0) {
          dispatch_err({
            type: "OPEN_MSG_BOX",
            payload: {
              header: "Success",
              message: res.data.data.msg,
            },
          });
          getEvalMarksStatus();
        } else {
          dispatch_err({
            type: "OPEN_MSG_BOX",
            payload: {
              header: "Message",
              message: res.data.data.msg,
            },
          });
          setLoader(false);
          return;
        }
      }
    } catch (error) {
      dispatch_err({
        type: "OPEN_MSG_BOX",
        payload: {
          header: "Error",
          message: error.message,
        },
      });
      setLoader(false);
      return;
    }
  };

  const getEvalMarksStatus = async () => {
    try {
      setLoader(true);
      const res = await axios.get(
        tdvsphp +
          "getEvalNaMarks&univcode=" +
          localStorage.getItem("univcode") +
          "&qpcode=" +
          qpcode +
          "&regcode=" +
          regcode
      );
      console.log(res.data.data);
      setLoader(false);
      setData(res.data.data.question);
      setFinalTotal(res.data.data.totmarks);
      setFinalOpen(true);
    } catch (error) {
      dispatch_err({
        type: "OPEN_MSG_BOX",
        payload: {
          header: "Error",
          message: error.message,
        },
      });
      setLoader(false);
      return;
    }
  };

  const hanldeOpenQp = () => {
    if (qpSchemepath.fonlineqp == "T") {
      window.open(
        "https://universitysolutions.in/univadmin/app.php?a=getOmrQpReport" +
          "&univcode=" +
          sessionStorage.getItem("univcode") +
          "&qpcode=" +
          qpcode +
          "&year=" +
          qpSchemepath.fyear +
          "&mode=" +
          qpSchemepath.fexamtype +
          "&teachcode=" +
          fteachcode +
          "&noqp=" +
          qpSchemepath.fset +
          "&type=" +
          qpSchemepath.ftype
      );
    } else {
      window.open(
        `https://answer-booklets.s3.ap-south-1.amazonaws.com/${qpSchemepath.fqppath}`
      );
    }
  };

  const rotateImage = (index) => {
    setRotation((prev) => {
      const newRotation = { ...prev };
      newRotation[index] = (newRotation[index] || 0) + 90; // Increase rotation by 90 degrees
      return newRotation;
    });
  };

  const zoomIn = (index) => {
    setZoomLevel((prev) => {
      const newZoom = { ...prev };
      newZoom[index] = (newZoom[index] || 1) + 0.1; // Increase zoom level by 10%
      return newZoom;
    });
  };

  const zoomOut = (index) => {
    setZoomLevel((prev) => {
      const newZoom = { ...prev };
      newZoom[index] = (newZoom[index] || 1) - 0.1; // Decrease zoom level by 10%
      return newZoom;
    });
  };

  const tableStyle = {
    border: "1px solid grey",
    borderCollapse: "collapse",
    textAlign: "center",
  };

  const input = {
    border: "0.5px solid black",
    padding: "5px",
    width: "60px",
    textAlign: "center",
  };

  const link = { color: "white", fontSize: 18, cursor: "pointer" };

  return (
    <div>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            width: "70%",
            height: "100vh",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
        >
          {filename.length > 0 ? (
            filename.map((el, i) => {
              return (
                <div
                  style={{
                    marginTop:
                      qpSchemepath.fonlineqp === "T" && qnModalOpen
                        ? "30px"
                        : 0,
                  }}
                  id={i}
                  key={i}
                >
                  <div
                    style={{
                      // display: "inline-block",
                      position: "relative",
                    }}
                  >
                    <img
                      onClick={() => console.log(i)}
                      src={`${API}/eval-img/${filepath}/${el}`}
                      alt="image not found"
                      style={{
                        transform: `rotate(${rotation[i] ||
                          0}deg) scale(${zoomLevel[i] || 1})`, // Apply both rotation and zoom dynamically
                        transition: "transform 0.3s ease",
                        width: "100%",
                        height: "100%", // Smooth transition for both zoom and rotation
                      }}
                    />
                    <div style={{ position: "absolute", top: "0", right: "0" }}>
                      <button
                        onClick={() => rotateImage(i)}
                        style={{
                          background: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "5px",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "12px",
                        }}
                      >
                        Rotate
                      </button>
                      <button
                        onClick={() => zoomIn(i)}
                        style={{
                          background: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "5px",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        Zoom In
                      </button>
                      <button
                        onClick={() => zoomOut(i)}
                        style={{
                          background: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "5px",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        Zoom Out
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>Answer Booklet Not Found</p>
          )}
        </div>
        <div
          style={{
            width: "30%",
            height: "100vh",
            overflowY: "auto",
            scrollbarWidth: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              background: "#008dda",
              padding: 10,
              position: "sticky",
              top: 0,
            }}
          >
            <a style={link} onClick={() => hanldeOpenQp()}>
              QP
            </a>
            <a
              style={link}
              href={`https://answer-booklets.s3.ap-south-1.amazonaws.com/${qpSchemepath.fschempath}`}
              target="_blank"
            >
              Scheme
            </a>
            <a style={link} onClick={() => saveHandler("save")}>
              Save
            </a>
            <a style={link} onClick={() => window.close()}>
              Exit
            </a>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              borderBottom: "0.5px solid  #000",
            }}
          >
            <div>
              {qpSchemepath.fonlineqp == "T" && (
                <IconGroup>
                  {isViewQn ? (
                    <Icon
                      name="eye"
                      style={{ cursor: "pointer" }}
                      color="green"
                      onClick={() => {
                        sessionStorage.setItem("isViewQn", false);
                        setIsViewQn(false);
                      }}
                    />
                  ) : (
                    <Icon
                      name="eye slash out"
                      style={{ cursor: "pointer" }}
                      color="red"
                      onClick={() => {
                        sessionStorage.setItem("isViewQn", true);
                        setIsViewQn(true);
                      }}
                    />
                  )}
                </IconGroup>
              )}
              <label>
                <b>Reg. Code.: </b>
              </label>
              <span>
                <b>{regcode}</b>
              </span>
            </div>
            <div>
              <label>
                <b>Qp. Code: </b>
              </label>
              <span>
                <b>{qpcode}</b>
              </span>
            </div>
            <div>
              <label>
                <b>Val. No.: </b>
              </label>
              <span>
                <b>{valno}</b>
              </span>
            </div>
          </div>

          <div
            style={{
              height: "90vh",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            <table
              style={tableStyle}
              width="100%"
              cellSpacing={5}
              cellPadding={4}
            >
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  background: "white",
                }}
              >
                <tr>
                  <th colSpan={10} style={tableStyle}>
                    <p>
                      <b style={{ color: "#f00", textDecoration: "underline" }}>
                        Note:{" "}
                      </b>
                      <span style={{ fontSize: "12px" }}>
                        If the student has not answered any question, kindly put
                        'N' in the appropriate box
                      </span>
                    </p>
                  </th>
                </tr>
                <tr style={{ background: "#008dda", color: "white" }}>
                  <th style={{ ...tableStyle, width: "10%" }}>Qn.</th>
                  <th style={{ ...tableStyle, width: "10%" }}>Max.</th>
                  <th style={{ ...tableStyle, width: "10%" }}>Page No.</th>
                  {novalData.filter((item) => item.fvalno == 1).length ===
                    1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarks11.toFixed(2)}
                    </th>
                  )}
                  {novalData.filter((item) => item.fvalno == 2).length ===
                    1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarks21.toFixed(2)}
                    </th>
                  )}
                  {novalData.filter((item) => item.fvalno == 3).length ===
                    1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarks31.toFixed(2)}
                    </th>
                  )}
                  {novalData.filter((item) => item.fvalno == 4).length ===
                    1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarks41.toFixed(2)}
                    </th>
                  )}
                  {novalData.filter((item) => item.fvalno == 5).length ===
                    1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarks51.toFixed(2)}
                    </th>
                  )}
                  {novalData.filter((item) => item.fvalno.toUpperCase() == "R")
                    .length === 1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarksr1.toFixed(2)}
                    </th>
                  )}
                  {novalData.filter((item) => item.fvalno.toUpperCase() == "D")
                    .length === 1 && (
                    <th style={{ ...tableStyle, width: "10%" }}>
                      Secured Marks: {totmarks.fmarksd1.toFixed(2)}
                    </th>
                  )}

                  {/* <th style={{ ...tableStyle, width: "10%" }}>Secured Marks: {totmarks.fmarksv1}</th> */}

                  {omrmarks !== "" && (
                    <th style={{ ...tableStyle, width: "10%" }} colSpan={2}>
                      OMR Marks: {omrmarks}
                    </th>
                  )}
                </tr>
              </thead>
              {parts.map((el, i) => {
                return (
                  <tbody key={el.fpartcode}>
                    <tr>
                      <th style={tableStyle} rowSpan={2}>
                        Qn.
                      </th>
                      <th style={tableStyle} rowSpan={2}>
                        Max.
                      </th>
                      <th style={tableStyle} rowSpan={2}>
                        Page No.
                      </th>
                      <th colSpan={8} style={tableStyle}>
                        {el.fpartdesc}
                      </th>
                    </tr>

                    <tr>
                      {novalData.filter((item) => item.fvalno == 1).length ===
                        1 && <th style={tableStyle}>Val 1</th>}
                      {novalData.filter((item) => item.fvalno == 2).length ===
                        1 && <th style={tableStyle}>Val 2</th>}
                      {novalData.filter((item) => item.fvalno == 3).length ===
                        1 && <th style={tableStyle}>Val 3</th>}
                      {novalData.filter((item) => item.fvalno == 4).length ===
                        1 && <th style={tableStyle}>Val 4</th>}
                      {novalData.filter((item) => item.fvalno == 5).length ===
                        1 && <th style={tableStyle}>Val 5</th>}
                      {novalData.filter(
                        (item) => item.fvalno.toUpperCase() == "R"
                      ).length === 1 && <th style={tableStyle}>Val R</th>}
                      {novalData.filter(
                        (item) => item.fvalno.toUpperCase() == "D"
                      ).length === 1 && <th style={tableStyle}>Val D</th>}
                      {/* <th style={tableStyle}>Val V</th> */}
                    </tr>
                    {questions.map((que, index) => {
                      if (que.fpartcode === el.fpartcode && que.fsubqn != "T") {
                        return (
                          <tr key={que.fqnno}>
                            <td style={tableStyle}>{que.fqnno}</td>
                            <td style={tableStyle}>{que.fmaxmarks}</td>
                            <td style={tableStyle}>
                              <input
                                style={input}
                                disabled={
                                  que.disabled == "true" || que.disabledstatus
                                    ? true
                                    : false
                                }
                                value={que.fpageno}
                                onChange={(e) =>
                                  changePageNoHandler(e, index, "fpageno")
                                }
                              />
                            </td>
                            {novalData.filter((item) => item.fvalno == 1)
                              .length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarks11")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarks11 <= -2.0 ||
                                      que.fmarks11 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarks11 == -1 || que.fmarks11 == -1.0
                                      ? ""
                                      : que.fmarks11 <= -2
                                      ? "N"
                                      : que.fmarks11
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarks11")
                                  }
                                />
                              </td>
                            )}
                            {novalData.filter((item) => item.fvalno == 2)
                              .length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarks21")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarks21 <= -2.0 ||
                                      que.fmarks21 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarks21 == -1 || que.fmarks21 == -1.0
                                      ? ""
                                      : que.fmarks21 <= -2
                                      ? "N"
                                      : que.fmarks21
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarks21")
                                  }
                                />
                              </td>
                            )}
                            {novalData.filter((item) => item.fvalno == 3)
                              .length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarks31")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarks31 <= -2.0 ||
                                      que.fmarks31 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarks31 == -1 || que.fmarks31 == -1.0
                                      ? ""
                                      : que.fmarks31 <= -2
                                      ? "N"
                                      : que.fmarks31
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarks31")
                                  }
                                />
                              </td>
                            )}
                            {novalData.filter((item) => item.fvalno == 4)
                              .length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarks41")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarks41 <= -2.0 ||
                                      que.fmarks41 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarks41 == -1 || que.fmarks41 == -1.0
                                      ? ""
                                      : que.fmarks41 <= -2
                                      ? "N"
                                      : que.fmarks41
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarks41")
                                  }
                                />
                              </td>
                            )}
                            {novalData.filter((item) => item.fvalno == 5)
                              .length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarks51")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarks51 <= -2.0 ||
                                      que.fmarks51 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarks51 == -1 || que.fmarks51 == -1.0
                                      ? ""
                                      : que.fmarks51 <= -2
                                      ? "N"
                                      : que.fmarks51
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarks51")
                                  }
                                />
                              </td>
                            )}
                            {novalData.filter(
                              (item) => item.fvalno.toUpperCase() == "R"
                            ).length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarksr1")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarksr1 <= -2.0 ||
                                      que.fmarksr1 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarksr1 == -1 || que.fmarksr1 == -1.0
                                      ? ""
                                      : que.fmarksr1 <= -2
                                      ? "N"
                                      : que.fmarksr1
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarksr1")
                                  }
                                />
                              </td>
                            )}

                            {novalData.filter(
                              (item) => item.fvalno.toUpperCase() == "D"
                            ).length === 1 && (
                              <td style={tableStyle}>
                                <input
                                  disabled={
                                    que.disabled == "true" || que.disabledstatus
                                      ? true
                                      : false
                                  }
                                  onBlur={() =>
                                    blurHandler(que, index, "fmarksd1")
                                  }
                                  type="text"
                                  style={{
                                    ...input,
                                    color:
                                      que.fmarksd1 <= -2.0 ||
                                      que.fmarksd1 == "N"
                                        ? "#f00"
                                        : "#000",
                                  }}
                                  value={
                                    que.fmarksd1 == -1 || que.fmarksd1 == -1.0
                                      ? ""
                                      : que.fmarksd1 <= -2
                                      ? "N"
                                      : que.fmarksd1
                                  }
                                  onChange={(e) =>
                                    changeMarksHandler(e, index, "fmarksd1")
                                  }
                                />
                              </td>
                            )}
                            {/* <td style={tableStyle}>
                              <input
                                onBlur={() =>
                                  blurHandler(que, index, "fmarksv1")
                                }
                                type="text"
                                style={{
                                  ...input,
                                  color:
                                    que.fmarksv1 <= -2.0 || que.fmarksv1 == "N"
                                      ? "#f00"
                                      : "#000",
                                }}
                                value={
                                  que.fmarksv1 == -1 || que.fmarksv1 == -1.0
                                    ? ""
                                    : que.fmarksv1 <= -2
                                    ? "N"
                                    : que.fmarksv1
                                }
                                onChange={(e) =>
                                  changeMarksHandler(e, index, "fmarksv1")
                                }
                              />
                            </td> */}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
      </div>
      <Modal
        open={finalOpen}
        size="large"
        onClose={() => setFinalOpen(false)}
        closeIcon
      >
        <Container style={{ padding: "1em" }}>
          <Card fluid style={{ padding: "1em" }}>
            <Card.Content textAlign="center">
              <Card.Header>
                Marks Details
                <Button
                  color="red"
                  size="tiny"
                  floated="right"
                  onClick={() => setFinalOpen(false)}
                >
                  Close
                </Button>
              </Card.Header>
              <Divider />
              <Card.Description>
                <div
                  style={{
                    height: "90vh",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                  }}
                >
                  <table
                    style={tableStyle}
                    width="100%"
                    cellSpacing={5}
                    cellPadding={4}
                  >
                    <thead
                      style={{
                        position: "sticky",
                        top: 0,
                        background: "white",
                      }}
                    >
                      <tr style={{ background: "#008dda", color: "white" }}>
                        <th style={{ ...tableStyle, width: "10%" }}>Qn.</th>
                        <th style={{ ...tableStyle, width: "10%" }}>Max.</th>
                        {novalData.filter((item) => item.fvalno == 1).length ===
                          1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "1")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "1"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "1")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "1"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}
                        {novalData.filter((item) => item.fvalno == 2).length ===
                          1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "2")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "2"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "2")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "2"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}
                        {novalData.filter((item) => item.fvalno == 3).length ===
                          1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "3")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "2"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "3")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "2"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}
                        {novalData.filter((item) => item.fvalno == 4).length ===
                          1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "4")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "4"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "4")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "4"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}
                        {novalData.filter((item) => item.fvalno == 5).length ===
                          1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "5")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "5"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "5")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "5"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}
                        {novalData.filter(
                          (item) => item.fvalno.toUpperCase() == "R"
                        ).length === 1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "R")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "R"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "R")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "R"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}
                        {novalData.filter(
                          (item) => item.fvalno.toUpperCase() == "D"
                        ).length === 1 && (
                          <>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sec. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "D")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "D"
                                )[0]["ftotmarks"]}
                            </th>
                            <th style={{ ...tableStyle, width: "10%" }}>
                              Sys. Marks:{" "}
                              {finalTotal.filter((item) => item.fvalno == "D")
                                .length > 0 &&
                                finalTotal.filter(
                                  (item) => item.fvalno == "D"
                                )[0]["fsysmarks"]}
                            </th>
                          </>
                        )}

                        {omrmarks !== "" && (
                          <th
                            style={{ ...tableStyle, width: "10%" }}
                            colSpan={2}
                          >
                            OMR Marks: {omrmarks}
                          </th>
                        )}
                      </tr>
                    </thead>
                    {parts.map((el, i) => {
                      return (
                        <tbody key={el.fpartcode}>
                          <tr>
                            <th style={tableStyle} rowSpan={2}>
                              Qn.
                            </th>
                            <th style={tableStyle} rowSpan={2}>
                              Max.
                            </th>
                            <th colSpan={8} style={tableStyle}>
                              {/* {el.fpartdesc} */}
                              {el.fpartdesc} (Answer {el.fmandqn} / {el.fmaxqn}{" "}
                              questions)
                            </th>
                          </tr>

                          <tr>
                            {novalData.filter((item) => item.fvalno == 1)
                              .length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val 1
                              </th>
                            )}
                            {novalData.filter((item) => item.fvalno == 2)
                              .length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val 2
                              </th>
                            )}
                            {novalData.filter((item) => item.fvalno == 3)
                              .length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val 3
                              </th>
                            )}
                            {novalData.filter((item) => item.fvalno == 4)
                              .length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val 4
                              </th>
                            )}
                            {novalData.filter((item) => item.fvalno == 5)
                              .length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val 5
                              </th>
                            )}
                            {novalData.filter(
                              (item) => item.fvalno.toUpperCase() == "R"
                            ).length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val R
                              </th>
                            )}
                            {novalData.filter(
                              (item) => item.fvalno.toUpperCase() == "D"
                            ).length === 1 && (
                              <th style={tableStyle} colSpan={2}>
                                Val D
                              </th>
                            )}
                          </tr>
                          {data.map((que, index) => {
                            if (
                              que.fpartcode === el.fpartcode &&
                              que.fsubqn != "T"
                            ) {
                              return (
                                <tr key={que.fqnno}>
                                  <td style={tableStyle}>{que.fqnno}</td>
                                  <td style={tableStyle}>{que.fmaxmarks}</td>
                                  {novalData.filter((item) => item.fvalno == 1)
                                    .length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks11 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks11 <= -2
                                          ? "N"
                                          : que.fmarks11 == "-1.0"
                                          ? ""
                                          : que.fmarks11}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks11 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks12 <= -2
                                          ? "N"
                                          : que.fmarks12 == "-1.0"
                                          ? ""
                                          : que.fmarks12}
                                      </td>
                                    </>
                                  )}
                                  {novalData.filter((item) => item.fvalno == 2)
                                    .length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks11 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks21 <= -2
                                          ? "N"
                                          : que.fmarks21 == "-1.0"
                                          ? ""
                                          : que.fmarks21}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks22 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks22 <= -2
                                          ? "N"
                                          : que.fmarks22 == "-1.0"
                                          ? ""
                                          : que.fmarks22}
                                      </td>
                                    </>
                                  )}
                                  {novalData.filter((item) => item.fvalno == 3)
                                    .length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks31 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks31 <= -2
                                          ? "N"
                                          : que.fmarks31 == "-1.0"
                                          ? ""
                                          : que.fmarks31}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks32 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks32 <= -2
                                          ? "N"
                                          : que.fmarks32 == "-1.0"
                                          ? ""
                                          : que.fmarks32}
                                      </td>
                                    </>
                                  )}
                                  {novalData.filter((item) => item.fvalno == 4)
                                    .length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks41 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks41 <= -2
                                          ? "N"
                                          : que.fmarks41 == "-1.0"
                                          ? ""
                                          : que.fmarks41}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks42 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks42 <= -2
                                          ? "N"
                                          : que.fmarks42 == "-1.0"
                                          ? ""
                                          : que.fmarks42}
                                      </td>
                                    </>
                                  )}
                                  {novalData.filter((item) => item.fvalno == 5)
                                    .length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks51 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks51 <= -2
                                          ? "N"
                                          : que.fmarks51 == "-1.0"
                                          ? ""
                                          : que.fmarks51}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarks52 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarks52 <= -2
                                          ? "N"
                                          : que.fmarks52 == "-1.0"
                                          ? ""
                                          : que.fmarks52}
                                      </td>
                                    </>
                                  )}
                                  {novalData.filter(
                                    (item) => item.fvalno.toUpperCase() == "R"
                                  ).length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarksr1 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarksr1 <= -2
                                          ? "N"
                                          : que.fmarksr1 == "-1.0"
                                          ? ""
                                          : que.fmarksr1}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarksr2 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarksr2 <= -2
                                          ? "N"
                                          : que.fmarksr2 == "-1.0"
                                          ? ""
                                          : que.fmarksr2}
                                      </td>
                                    </>
                                  )}

                                  {novalData.filter(
                                    (item) => item.fvalno.toUpperCase() == "D"
                                  ).length === 1 && (
                                    <>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarksd1 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarksd1 <= -2
                                          ? "N"
                                          : que.fmarksd1 == "-1.0"
                                          ? ""
                                          : que.fmarksd1}
                                      </td>
                                      <td
                                        style={{
                                          ...tableStyle,
                                          color:
                                            que.fmarksd2 <= -2
                                              ? "#f00"
                                              : "#000",
                                        }}
                                      >
                                        {que.fmarksd2 <= -2
                                          ? "N"
                                          : que.fmarksd2 == "-1.0"
                                          ? ""
                                          : que.fmarksd2}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      );
                    })}
                  </table>
                </div>
              </Card.Description>
            </Card.Content>
          </Card>
        </Container>
      </Modal>
    </div>
  );
};

export default NaMismatchValuation;

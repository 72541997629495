import axios from "axios";
import { API } from "./consts";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const fpPromise = FingerprintJS.load();

(async () => {
  // Get the visitor identifier when you need it.
  const fp = await fpPromise;
  const result = await fp.get();

  // This is the visitor identifier:
  const visitorId = result.visitorId;
})();

const getToken = () => {
  return sessionStorage.getItem("token");
};

const getUnivcode = () => {
  return sessionStorage.getItem("univcode");
};

const http = axios.create({
  baseURL: API,
  headers: {
    "X-Auth-Origin": "TDVS",
  },
});
http.defaults.params = {};
http.interceptors.request.use(
  function(config) {
    const token = getToken();
    const univcode = getUnivcode();
    if (univcode) config.params["univcode"] = univcode;
    if (token) config.headers["Authorization"] = token;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default http;
